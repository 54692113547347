import React, { ReactNode } from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { Text, H1, Box } from '@parsleyhealth/cilantro-ui';
import BlogHeroLayout from './blog-hero-layout';
import { SanityBasicImage } from 'graphql-types';

interface BlogPostHeroProps {
  title?: string;
  category?: string;
  author?: ReactNode;
  url?: string;
  image?: SanityBasicImage;
}

const LinkContainer = styled(Box)`
  cursor: pointer;
`;

const BlogPostHero = ({
  title,
  category,
  image,
  author,
  url
}: BlogPostHeroProps) => {
  const handleOnClick = () => navigate(url);
  const hero = (
    <BlogHeroLayout image={image} py="xxl">
      {category && (
        <Text color="white" mt="xxl" mb="xs">
          {category.replace('&amp;', '&').toUpperCase()}
        </Text>
      )}
      <H1 color="white" mb={author ? 's' : 'xxl'}>
        {title}
      </H1>
      {author && <Box>{author}</Box>}
    </BlogHeroLayout>
  );
  return url ? (
    <LinkContainer onClick={handleOnClick}>{hero}</LinkContainer>
  ) : (
    <Box>{hero}</Box>
  );
};

export default BlogPostHero;
